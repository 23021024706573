import { Button, Row, Space } from 'antd'
import { colorList } from 'components/AccountGroupFilter/GroupForm'
import { getIconByGroup } from 'components/CheckboxIconTag/CheckboxIconTag'
import { TaskGroup } from 'types/Tasks'
import { GroupCategory } from 'types/Categories'

type GroupCategoryButtonProps = {
  onClick?: (selectedItem: GroupCategory | TaskGroup) => void
  data: GroupCategory | TaskGroup

}

const GroupCategoryButton = ({ onClick, data }: GroupCategoryButtonProps) => {
  return (
    <Space.Compact block={true}>
      <Button
        type="primary"
        block
        onClick={() => onClick?.(data)}
        style={{
          textAlign: 'left',
          height: 40,
          width: '100%',
          border: `1px solid white`,
          borderRadius: '6px',
          outline: `2px solid ${data.metadata.color || colorList[0]}`,
          backgroundColor: data.metadata.color || colorList[0],
          color: '#ffffff',
          margin: '8px',
        }}
      >
        <Space size={4}>
          <Row align="middle">
            {getIconByGroup(data.metadata.icon)}
            {data.title}
          </Row>
        </Space>
      </Button>
    </Space.Compact>
  )
}

export default GroupCategoryButton

import { SettingOutlined } from '@ant-design/icons'
import { App, Button, Col, Row, Select } from 'antd'
import GroupCategoryButton from 'components/GroupCategoryButton'
import LoadingProgress from 'components/LoadingProgress/LoadingProgress'
import useStore from 'hooks/useStore'
import useSubscription from 'hooks/useSubscription'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { TaskGroup } from 'types/Tasks'
import { hexToRgb } from 'utils/taskUtils'
import AccountFilter from './AccountFilter/AccountFilter'
import styles from './AccountGroupFilter.module.scss'
import AddGroupModal from './AddGroupModal'
import GroupFilter from './GroupFilter/GroupFilter'

type AccountGroupFilterProps = {
  label: string
}

const AccountGroupFilter = ({ label }: AccountGroupFilterProps) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [selectedOption, setSelectedOption] = useState<number | undefined>(
    undefined,
  )
  const [isAddGroupModalOpen, setIsAddGroupModalOpen] = useState(false)
  const {
    categories,
    getGroupCategory,
    getSubscriptionPackage,
    getUserConfig,
    getUserGroups,
    getWPLoginUrl,
    selectedCategory,
    selectedGroups,
    setCurrentCategory,
    setSelectedGroups,
    tasks,
  } = useStore((state) => state)
  const user = useStore((state) => state.user?.data)
  const email = user?.email || ''
  const app = App.useApp()
  const { updateBillingUrl, updateSubscriptionPackage } = useSubscription(
    (state) => state,
  )

  const selectAllOption = useMemo(() => {
    return {
      label: <span>{t('my-today-page.select-all')}</span>,
      value: -2,
      title: t('my-today-page.select-all'),
    }
  }, [t])

  const unselectAllOption = useMemo(() => {
    return {
      label: <span>{t('my-today-page.unselect-all')}</span>,
      value: -1,
      title: t('my-today-page.unselect-all'),
    }
  }, [t])

  const categorySelectionChanged = useCallback(
    (selectedValue: number) => {
      setSelectedOption(selectedValue)
      if (selectedValue === selectAllOption.value) {
        setCurrentCategory(0)
        if (tasks.groups?.length) {
          setSelectedGroups(tasks.groups.map((group) => group.id))
        }
      } else if (selectedValue === unselectAllOption.value) {
        setCurrentCategory(0)
        setSelectedGroups([])
      } else {
        setCurrentCategory(selectedValue)
        const category = categories.find((cat) => cat.id === selectedValue)
        if (category) {
          setSelectedGroups(category.categoryGroups || [])
        }
      }
    },
    [
      categories,
      selectAllOption.value,
      setCurrentCategory,
      setSelectedGroups,
      tasks.groups,
      unselectAllOption.value,
    ],
  )
  const categoryOptionList = useMemo(() => {
    const options = categories.map((category) => {
      return {
        label: <GroupCategoryButton data={category} />,
        value: category.id,
        title: category.title,
      }
    })
    return [selectAllOption, unselectAllOption, ...options]
  }, [categories, selectAllOption, unselectAllOption])

  const style = useMemo(() => {
    if (selectedCategory) {
      const category = categories.find((cat) => cat.id === selectedCategory)
      if (category?.metadata?.color) {
        const rgb = hexToRgb(category.metadata.color)
        if (rgb) {
          return { backgroundColor: `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.3)` }
        }
      }
    }
    return {}
  }, [categories, selectedCategory])

  useEffect(() => {
    getGroupCategory()
  }, [getGroupCategory])

  useEffect(() => {
    getWPLoginUrl()
      .then((data) => {
        if (data) {
          updateBillingUrl(data)
        }
      })
      .catch((error) => {
        updateBillingUrl('')
      })
  }, [getWPLoginUrl, updateBillingUrl])

  useEffect(() => {
    getSubscriptionPackage().then((data) => {
      const { package: pkg } = data
      const snake_case_pkg = pkg
        .toLowerCase() // Converts the string to lowercase
        .replace(/\s+/g, '_') // Replaces spaces with _

      updateSubscriptionPackage(snake_case_pkg)
    })
  }, [getSubscriptionPackage, updateSubscriptionPackage])

  useEffect(() => {
    setLoading(true)
    getUserGroups()
      .then((data: TaskGroup[]) => {
        getUserConfig().then((value) => {
          if (value.preferences?.selectedGroups) {
            setSelectedGroups(value.preferences.selectedGroups, true)
          } else {
            setSelectedGroups(
              data
                .filter(
                  (group) =>
                    group.groupMembers?.find((gm) => gm.user === user?.id)
                      ?.status !== 'inactive' && group.status !== 'archived',
                )
                .map((group) => group.id),
            )
          }
        })
      })
      .catch((error) => {
        const message = error.response?.data?.fallback_message || error.message
        app.notification.error({
          message,
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }, [
    setLoading,
    getUserConfig,
    getUserGroups,
    setSelectedGroups,
    app.notification,
    user?.id,
  ])

  const changeFilterStatus = useCallback(
    (id: number) => {
      const currentFilterIndex = selectedGroups.indexOf(id)
      const newFilters =
        currentFilterIndex > -1
          ? selectedGroups.filter((filter) => filter !== id)
          : selectedGroups.concat(id)
      setSelectedGroups(newFilters)
      setSelectedOption(undefined)
      setCurrentCategory(0)
    },
    [selectedGroups, setCurrentCategory, setSelectedGroups],
  )

  if (loading) {
    return <LoadingProgress />
  }

  return (
    <Row
      gutter={16}
      wrap={false}
      justify="space-between"
      className={styles.filtersContainer}
      style={style}
    >
      <Col flex="1 1 0px">
        <Row wrap={false}>
          <Col>
            <AccountFilter label={email} />
          </Col>
          <Col flex={1}>
            <GroupFilter
              filters={
                tasks?.groups?.filter(
                  (group) =>
                    group.groupMembers?.find((gm) => gm.user === user?.id)
                      ?.status !== 'inactive' && group.status !== 'archived',
                ) || []
              }
              selected={selectedGroups}
              onChange={changeFilterStatus}
            />
          </Col>
        </Row>
      </Col>
      <Col flex="0 1 auto">
        <Row justify="space-between">
          <Col>
            <Select
              style={{ width: '200px' }}
              placeholder={t('settings.categories.select-category')}
              value={selectedCategory || selectedOption}
              onChange={categorySelectionChanged}
              options={categoryOptionList}
              labelRender={(option) => {
                if ('title' in option) {
                  return <span>{option.title as string}</span>
                } else {
                  return <span>{option.label}</span>
                }
              }}
            />
          </Col>
          <Col>
            <Link to="/settings?activeTab=groups">
              <Button
                icon={<SettingOutlined />}
                size="small"
                type="text"
              ></Button>
            </Link>
          </Col>
        </Row>
      </Col>
      <AddGroupModal
        isOpen={isAddGroupModalOpen}
        handleClose={() => setIsAddGroupModalOpen(false)}
      />
    </Row>
  )
}

export default AccountGroupFilter

import Routes from './Routes'
import Providers from './AppProvider'
import VersionBanner from './components/VersionBanner'

const App = () => {
  return (
    <Providers>
      <VersionBanner />
      <Routes />
    </Providers>
  )
}

export default App
